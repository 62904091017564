.App {
    text-align: center;
}

.explain {
    font-size: 20px;
    margin-top: 2px;
    text-align: center;
    font-style: italic;
}

.next button {
    margin-top: 20px;
    font-size: 40px;
}

.ElectPuzzle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ElectPuzzle span {
    display: flex;
    justify-content: center;
    font-size: 40px;
    margin: 2px;
    padding: 15px;
    border: 1px solid grey;
    width: 25px;
    text-align: center;
}

.ShopPuzzle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ShopPuzzle span {
    display: flex;
    justify-content: center;
    font-size: 40px;
    margin: 2px;
    padding: 15px;
    border: 1px solid grey;
    width: calc(100% - 4px);
    text-align: center;
}

.PuzzleJak {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PuzzleJak span {
    display: flex;
    justify-content: center;
    font-size: 40px;
    margin: 2px;
    padding: 15px;
    border: 1px solid grey;
    width: calc(100% - 4px);
    text-align: center;
}

.PuzzleJak input {
    width: 60px;
    font-size: 30px;
    margin-left: 20px;
}